.theo-primary-color,
.vjs-selected {
  color: #61dafb !important;
}

.theo-primary-background {
  color: #000000 !important;
  background-color: #61dafb !important;
}

.theo-secondary-color {
  color: #ffffff !important;
}

.theo-secondary-background {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.fa-step-backward {
  order: -101; /* to the left of the play/pause-button */
  cursor: pointer;
}
.fa-step-forward {
  order: -100; /* to the right of the play/pause-button */
  cursor: pointer;
}

.spalla-rewind {
  /* na esquerda do botão de play/pause */
  order: -101;
  cursor: pointer;
}

.spalla-forward {
  /* na direita do botão de play/pause */
  order: -100;
  cursor: pointer;
}

.theo-tertiary-color {
  color: #000000 !important;
}

.theo-tertiary-background {
  color: #ffffff !important;
  background-color: #000000 !important;
}
