.arena-chat {
  height: 100% !important;
}

.arena-chat-widget--container {
  border-radius: 16px !important;
}

@media (max-width: 960px) {
  .arena-chat-widget--container {
    border-radius: 0px !important;
  }
}

/* container do chat */
.dOAHAa {
  height: 100% !important;
}

.chat-room--tabs--tabs {
  background-color: #2a2a2a !important;
}

.chat-room--tabs--tab {
  border-bottom: 2px solid #7e3ae7;
}

.chat-room--tabs--tab {
  color: white !important;
}

.chat-room--tabs--tab.jvptNl {
  color: #7e3ae7 !important;
}

.chat-room--chat--wrapper {
  background-color: #2a2a2a !important;
  border: none;
}

/* botão de emoji que fica ao lado do input */
.react-input-emoji--button {
  display: none !important;
}

.arena-chat-widget--message-reply-text {
  color: #ffffff !important;
  font-weight: 600;
}

.chat-room--tabs--container {
  background-color: #2a2a2a !important;
}

.live-chat-room-input--input--wrapper {
  background-color: #1f1f1f !important;
  padding-bottom: 0px !important;
}

.live-chat-room {
  background-color: #2a2a2a !important;
}

.style__ChatRoomPoweredBy-cumuKb {
  display: none !important;
}

/* ícone de som */
.chat-room--chat--header--sound svg path {
  fill: white !important;
}

.style__ChatRoomHeaderMenuContainer-PdYYx {
  display: none !important;
}

/* ícone da quantidade de pessoas online */
.chat-room--chat--header--users--number--container svg path {
  fill: white !important;
}

.chat-room--chat--header--wrapper {
  background-color: #7e3ae7 !important;
}

.arena-chat-widget--message-content-container-wrapper {
  background-color: #1f1f1f !important;
}

.arena-chat-widget--message-content-text {
  color: #b5b5c3 !important;
}

.arena-chat-widget--message-body::before {
  content: '' !important;
  border: none !important;
}

/* Caixa que fica ao lado da mensagem */
.arena-chat-widget--message-tools-container {
  background-color: #2a2a2a !important;
}

.arena-chat-widget--message-content-reactions-container {
  background-color: #1f1f1f !important;
}

/* container dos emojis */
.react-emoji-picker--wrapper {
  height: 350px !important;
}

/* container dos emojis */
em-emoji-picker {
  height: 350px !important;
}

/* container dos gifs */
#chat-room-input-gif--gifs {
  background-color: #1f1f1f !important;
}

/* container do input dos gifs */
.style__ChatRoomInputGIFSearchWrapper-ddAFah {
  background-color: #2a2a2a !important;
}

/* input dos gifs */
.style__ChatRoomInputGIFSearch-kERnAS {
  background-color: #2a2a2a !important;
  border: 1px solid #3f4254 !important;
}

/* botão de sair */
.chat-room--private--group-channels--menu--menu--item-logout {
  display: none !important;
}

/* botão de sair no perfil do usuário */
.style__ChatRoomUserProfileLogOut-dqyZmK {
  display: none !important;
}

/* botão de bloquear no menu do usuário */
.chat-room--private--group-channels--menu--menu--item-block {
  display: none !important;
}

/* container do perfil do usuário */
.style__ChatRoomUserProfileContainer-jaRMOj {
  background-color: #2a2a2a !important;
  height: 100% !important;
}

/* nome perfil no header */
.style__ChatRoomUserProfileBackLink-jLxzQn {
  color: #ffffff !important;
}

.style__ChatRoomUserProfileBackLink-jLxzQn svg path {
  fill: #ffffff !important;
}

/* nome do usuário */
.style__ChatRoomUserProfileName-jhxIyj {
  color: #ffffff !important;
}

/* id do usuário */
.style__ChatRoomUserProfileHandle-hOsvRy {
  color: #ffffff !important;
}

/* container do footer - perfil do usuário */
.jAVLXJ {
  border-top: none !important;
}

/* footer do perfil do usuário */
.style__ChatRoomUserProfileFooter-hLfyAJ {
  background-color: #2a2a2a !important;
}

/* botão bloquear usuário */
.style__ChatRoomUserProfileBlock-eQZeoV {
  border-top: 1px solid rgb(227, 228, 235);
  justify-content: center !important;
  padding-top: 8px !important;
  width: 100% !important;
}
/* Q&A - enquete */

/* frase "veja todas as perguntas */
.style__ChatRoomMessageContentLinkButton-aNIXS,
.style__ChatRoomMessageContentLinkButtonIcon-dSFHkZ svg path {
  color: #7e3ae7 !important;
  fill: #7e3ae7 !important;
}

/* Header */
.eXuouC {
  background-color: #2a2a2a !important;
}

/* container */
.ldUgnv {
  height: 100% !important;
}

/* Nome 'voltar', Nome 'Live Q&A', Nome 'popular' */
.style__ChatRoomScreenHeaderLeft-hVOBcK span,
.style__ChatRoomScreenHeaderTitle-bnhnAV span,
.style__ChatRoomScreenHeaderFiltersButton-fdYmsA {
  color: #ffffff !important;
}

/* setinhas */
.style__ChatRoomScreenHeaderBack-bBGoim svg path,
.style__ChatRoomScreenHeaderFiltersButtonIcon-ecOgff svg path {
  fill: #ffffff !important;
}

/* nome do usuário que fez a pergunta, horário da pergunta, pergunta */
.style__QnaListItemMessageUserName-fPRqTD,
.style__QnaListItemMessageTime-kqiwZg,
.style__QnaListItemMessageText-hkmSMY {
  color: #b5b5c3 !important;
}

/* status da pergunta */
.style__QnaListItemStatus-dcRUbu {
  color: #7e3ae7 !important;
}

.style__QnaInputInput-ksvKCB {
  color: #ffffff !important;
}

/* enquetes */
/* container */
.ezmIWL {
  height: 100% !important;
}

/* body */
.style__PollsBody-gPfgqP {
  background-color: #2a2a2a !important;
}

/* nome "todas as enquetes", setinha */
.style__ChatRoomPollSeeAllButton-jZPoN,
.style__ChatRoomPollSeeAllButton-jZPoN svg path {
  color: #7e3ae7 !important;
  fill: #7e3ae7 !important;
}

.style__ChatRoomPollContainer-ouyKC {
  border: none !important;
  background-color: #1f1f1f !important;
}

.style__PollContainer-dSPpFX {
  background-color: #1f1f1f !important;
}

.style__PollRemainingTime-esGxph {
  color: #7e3ae7 !important;
}

.style__PollQuestion-geFRPa {
  color: #f3f6f9 !important;
  font-weight: 600 !important;
}

.style__ChatRoomPollCTAs-irgVeo {
  border-top: 1px solid #3f4254;
}

.style__ChatRoomPollSeeAllButton-jZPoN {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  margin-bottom: 0px !important;
}

.bJkKKA {
  background-color: #7e3ae7 !important;
}

.style__PollOptionTitle-aFYDL {
  color: #f3f6f9 !important;
}

.style__PollVotesCounter-bYuRsT {
  color: #7e3ae7 !important;
}

/* card */
.style__ConversionCard-sfQqz {
  height: 100% !important;
}

.chat-form .arena--conversion-card-form--form-wrapper {
  width: 100% !important;
}

.style__ConversionCardFormItem-jcNeoc {
  padding: 0px !important;
}

.style__ConversionCardOverlay-hAHVud {
  background-color: #1f1f1f !important;
}

.style__ConversionCardFormText-iSUQis {
  color: #ffffff !important;
}

.style__ConversionCard-sfQqz {
  border-radius: 8px !important;
  background-color: #1f1f1f !important;
}

.arena--conversion-card,
.dkvcY {
  border: none !important;
}

.bDNNFt {
  border: none !important;
}

.style__ConversionCardBasicText-jkcjyU {
  color: #ffffff !important;
}

/* título do input */
.style__ConversionCardFormInputTextWrapper-fIURzt label {
  padding-left: 0px !important;
  color: #7e3ae7 !important;
}

.style__ConversionCardFormInputTextWrapper-fIURzt input {
  background-color: #2a2a2a !important;
  border: 1px solid #3f4254 !important;
}

/* título do cartão */
.style__ConversionCardTitle-jZbYcr.flrqiV {
  color: #f3f6f9 !important;
  font-weight: 600 !important;
}

.style__ConversionCardTitle-jZbYcr.cJTotk {
  color: #f3f6f9 !important;
  font-weight: 600 !important;
}

/* descrição do cartão */
.style__ConversionCardText-euvJFO {
  color: #f3f6f9 !important;
}

/* botão */
.style__ConversionCardCtasButton-cgjYoY {
  color: #f3f6f9 !important;
  width: 100% !important;
  text-align: center !important;
}
