// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
@import './_metronic/_assets/sass/themes/layout/aside/light.scss';

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.slick-arrow:before {
  color: $primary;
}

.alice-carousel__stage {
  display: flex
}
.alice-carousel__stage-item {
  flex: 1 0 auto;
  height: auto;
}
.item {
  height: 100%;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.image-input {
  border-width: 2px !important;
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.custom-control {
  cursor: pointer !important;
}

.custom-switch .custom-control-label::before {
  cursor: pointer !important;
}

.custom-switch .custom-control-label::after {
  cursor: pointer !important;
}

.react-datetime-picker__wrapper {
  padding: 5px;
  border: 1px solid #e4e6ef;
  border-radius: 4px;
}

.video-thumbnail {
  width: 232px;
  height: 131px;
  background-color: #000;
  background-image: url('/media/misc/player-thumbnail.png');
  background-size: cover;
  position: relative;
  flex-shrink: 0;
}
.video-title {
  left: 16px;
  top: 16px;
  position: absolute;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  /* Neutral / White */

  color: #ffffff;
}

.key-card-container {
  display: flex;
}
.key-card {
  z-index: 10;
  width: 359px;

  margin-top: 6px;
  /* Neutral / Light */

  background: #f3f6f9;
  /* Dropdown Shadow */

  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 17px;
  text-align: center;
}

.image-input {
  position: relative;
  width: 233px;
  height: 131px;
  border: 1.5px dashed #ebedf3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}
.image-input input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}
.image-input img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-input.responsive {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  position: relative;
}

.image-input.responsive img {
  left: 0;
  top: 0;
}

.overlay-inputs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  z-index: 100;
}
